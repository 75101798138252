/* Anything shared between sidebar & mapbox goes here */
import convert from 'convert-units';
import React, { createContext, MutableRefObject, useContext, useEffect, useRef, useState } from 'react';
import { CustomTarget, DEFAULT_CUSTOM_TARGET, EntityType, SelectedRLDOptions, StageType, Statistics } from '../common/types';
import { getTerminology } from '../i18n';

interface MapStateContextType {
  lowerBound: number;
  setLowerBound: React.Dispatch<React.SetStateAction<number>>;
  upperBound: number;
  setUpperBound: React.Dispatch<React.SetStateAction<number>>;
  viewZoneMap: boolean;
  setViewZoneMap: React.Dispatch<React.SetStateAction<boolean>>;
  map: MutableRefObject<mapboxgl.Map | null>;
  plotConfigs: {
    [key: string]: any;
  };
  isImperial: boolean;
  setIsImperial: React.Dispatch<React.SetStateAction<boolean>>;
  selectedRLDOptions: SelectedRLDOptions;
  setSelectedRLDOptions: React.Dispatch<React.SetStateAction<SelectedRLDOptions>>;
  rowStats: { [key: number]: number };
  setRowStats: React.Dispatch<React.SetStateAction<{ [key: number]: number }>>;
  numTargetRows: number;
  setNumTargetRows: React.Dispatch<React.SetStateAction<number>>;
  customTarg: CustomTarget;
  setCustomTarg: React.Dispatch<React.SetStateAction<CustomTarget>>;
  stageType: StageType | null;
  setStageType: React.Dispatch<React.SetStateAction<StageType | null>>;
  isTaskMapDialogOpen: boolean;
  setIsTaskMapDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const MapStateContext = createContext<MapStateContextType | undefined>(undefined);
// Anything shared between Sidebar & Mapbox goes here

export const MapStateProvider: React.FC<{ children: React.ReactNode, initialStageType?: StageType | null }> = ({ 
  children, 
  initialStageType = null 
}) => {
  const [lowerBound, setLowerBound] = useState(60);
  const [upperBound, setUpperBound] = useState(140);
  const [viewZoneMap, setViewZoneMap] = useState(false);
  const [isImperial, setIsImperial] = useState(true);
  const map = useRef<mapboxgl.Map | null>(null);
  const [stageType, setStageType] = useState<StageType | null>(initialStageType);
  const [isTaskMapDialogOpen, setIsTaskMapDialogOpen] = useState<boolean>(false);

  // For RLD Heatmap
  const [selectedRLDOptions, setSelectedRLDOptions] = useState<SelectedRLDOptions>({
    ccsa_red_m2: true,
    ccsa_yellowred_m2: false,
    ccsa_yellow_m2: false,
    ccsa_wilted_m2: false
  });

  // For custom target
  const [customTarg, setCustomTarg] = useState<CustomTarget>(DEFAULT_CUSTOM_TARGET);

  // For Row Distribution Similarity Feature
  const [rowStats, setRowStats] = useState<{ [key: number]: number }>({});
  const [numTargetRows, setNumTargetRows] = useState<number>(0);

  const safeNumber = (value: number) => {
    const num = Number(value);
    if (!Number.isFinite(num)) {
      return 0; 
    }
    return Number(num.toFixed(2)); 
  };

  // Helper function to get stage-dependent text
  const getStageTerm = (stageType: StageType | null) => {
    if (stageType === StageType.Bud) {
      return {
        singular: 'bud',
        plural: 'buds',
        Singular: 'Bud',
        Plural: 'Buds',
      };
    } else {
      return {
        singular: 'fruit',
        plural: 'fruits',
        Singular: 'Fruit',
        Plural: 'Fruits',
      };
    }
  };

  const stage = getStageTerm(stageType);

  const plotConfigs = {
    'uniform': {
      caption: `${stage.Plural} per ${getTerminology('Tree')}`,
      feature: 'num_buds',
      unit: stage.plural,
      legend: 'standard',
      enableZone: true,
      label: `${stage.Singular} Count`,
      description: `Heatmap that shows the average ${stage.singular} count per ${getTerminology('tree')}`,
      entityType: EntityType.Fruits,
      stageType: [
        StageType.Bud,
        StageType.SilverTip,
        StageType.GreenTip,
        StageType.TightCluster,
        StageType.Pink,
        StageType.KingBloom,
        StageType.FullBloom,
        StageType.PetalFall,
        StageType.Fruit
      ],
      adminOnly: false,
      bounds: [60, 140],
      fetchTargFromStats: (stats: Statistics, entity?: EntityType, stageType?: StageType, selectedRLDOptions?: SelectedRLDOptions) => {
        return Math.round(stats.fruit_per_tree_calibrated > 0 ? stats.fruit_per_tree_calibrated : stats.fruit_per_tree_detected);
      }
    },
    'size': {
      caption: `Fruit Size per ${getTerminology('Tree')}`,
      feature: 'avg_diam',
      unit: 'mm',
      legend: 'standard',
      enableZone: true,
      label: 'Fruit Size',
      description: `Heatmap that shows the average fruit size per ${getTerminology('tree')}`,
      entityType: EntityType.Fruits,
      stageType: [StageType.EarlyFruitSet, StageType.Fruit],
      adminOnly: false,
      bounds: [95, 105],
      fetchTargFromStats: (stats: Statistics, entity?: EntityType, stageType?: StageType, selectedRLDOptions?: SelectedRLDOptions) => {
        if (entity === EntityType.Fruits) {
          if (stageType === StageType.EarlyFruitSet) {
            return safeNumber(stats.avg_fruitlet_diam ?? 0);
          }
          if (stageType === StageType.Fruit) {
            return safeNumber(stats.avg_fruit_diam ?? 0);
          }
        }
        return 0;
      }
    },
    'color': {
      caption: 'Fruit Color',
      feature: 'avg_fruit_hue',
      unit: '',
      legend: 'hue',
      enableZone: false,
      label: 'Fruit Color',
      description: `Heatmap that shows the average color of fruits per ${getTerminology('tree')}`,
      entityType: EntityType.Fruits,
      adminOnly: false,
      bounds: [-25, 25],
      fetchTargFromStats: (stats: Statistics, entity?: EntityType, stageType?: StageType, selectedRLDOptions?: SelectedRLDOptions) => {
        return safeNumber(stats.avg_hue ?? 0);
      }
    },
    'tree-vigor': {
      caption: 'Canopy Area',
      feature: 'canopy_area_m2',
      unit: 'm\u00B2',
      legend: 'standard',
      enableZone: true,
      label: `Canopy Vigor`,
      description: `Heatmap that shows the canopy area of a ${getTerminology('tree')}`,
      adminOnly: false,
      bounds: [70, 130],
      fetchTargFromStats: (stats: Statistics, entity?: EntityType, stageType?: StageType, selectedRLDOptions?: SelectedRLDOptions) => {
        return safeNumber(stats.avg_canopy_area_m2 ?? 0);
      }
    },
    'tree_diam': {
      caption: 'Trunk Diameter',
      feature: 'width',
      unit: 'in',
      legend: 'standard',
      enableZone: true,
      label: 'Trunk Diameter',
      description: `Heatmap that shows trunk diameter per ${getTerminology('tree')}`,
      adminOnly: false,
      bounds: [80, 120],
      fetchTargFromStats: (stats: Statistics, entity?: EntityType, stageType?: StageType, selectedRLDOptions?: SelectedRLDOptions) => {
        return safeNumber(stats.avg_tree_diam_in ?? 0);
      }
    },
    'tree_area': {
      caption: 'Trunk Area',
      feature: 'xs_area_in2',
      unit: 'in²',
      legend: 'standard',
      enableZone: true,
      label: 'Trunk XS Area',
      description: `Heatmap that shows trunk cross-sectional area per ${getTerminology('tree')}`,
      adminOnly: false,
      bounds: [75, 125],
      fetchTargFromStats: (stats: Statistics, entity?: EntityType, stageType?: StageType, selectedRLDOptions?: SelectedRLDOptions) => {
        return safeNumber((stats.avg_trunk_xsarea ?? 0) * 0.155);
      }
    },
    'trunk_height': {
      caption: 'Trunk Height',
      feature: 'trunk_height_m',
      unit: 'm',
      legend: 'standard',
      enableZone: true,
      label: 'Trunk Height',
      description: `Heatmap that shows trunk height per ${getTerminology('tree')}`,
      adminOnly: false,
      bounds: [80, 120],
      fetchTargFromStats: (stats: Statistics, entity?: EntityType, stageType?: StageType, selectedRLDOptions?: SelectedRLDOptions) => {
        return safeNumber(stats.avg_trunk_height_m ?? 0);
      }
    },
    'spacing': {
      caption: 'Trunk Spacing',
      feature: 'tree_spacing',
      unit: 'ft',
      legend: 'standard',
      enableZone: true,
      label: 'Trunk Spacing',
      description: `Heatmap that shows the spacing between ${getTerminology('trees')}`,
      adminOnly: false,
      bounds: [80, 120],
      fetchTargFromStats: (stats: Statistics, entity?: EntityType, stageType?: StageType, selectedRLDOptions?: SelectedRLDOptions) => {
        return safeNumber(stats.avg_tree_spacing_ft ?? 0);
      }
    },
    'canopy_hue': {
      caption: 'Canopy Hue',
      feature: 'canopy_hue',
      unit: '',
      legend: 'hue',
      enableZone: false,
      label: 'Canopy Hue (admin)',
      description: `Heatmap that shows the canopy hue of a ${getTerminology('tree')}`,
      entityType: EntityType.Fruits,
      adminOnly: true,
      bounds: [-25, 25],
      fetchTargFromStats: (stats: Statistics, entity?: EntityType, stageType?: StageType, selectedRLDOptions?: SelectedRLDOptions) => {
        return safeNumber(stats.avg_canopy_hue ?? 0);
      }
    },
    'emitters': {
      caption: 'Emitters',
      feature: 'emitters',
      unit: '',
      legend: '',
      enableZone: false,
      label: 'Emitter Positions',
      description: "Shows emitter positions on the map",
      adminOnly: false,
      fetchTargFromStats: (stats: Statistics, entity?: EntityType, stageType?: StageType, selectedRLDOptions?: SelectedRLDOptions) => {
        return 0; // No specific target for emitters
      }
    },
    'rld': {
      caption: 'RLD',
      feature: 'rld',
      unit: isImperial ? 'ft²' : 'm²',
      legend: 'RLD',
      enableZone: false,
      label: 'Red Leaf Disease (RLD) (admin)',
      description: "Heatmap that shows red leaf disease (RLD) per vine",
      entityType: EntityType.Fruits,
      adminOnly: true,
      convert: (value: number) => {
        return isImperial
          ? convert(value).from('m2').to('ft2')
          : convert(value).from('ft2').to('m2');
      },
      bounds: [0, 150],
      fetchTargFromStats: (stats: Statistics, entity?: EntityType, stageType?: StageType, selectedRLDOptions?: SelectedRLDOptions) => {
        if (!stats || !selectedRLDOptions) return 0;
        
        // Sum up the selected RLD options
        const sumRLD = Object.entries(selectedRLDOptions)
          .filter(([_, isSelected]) => isSelected)
          .reduce((sum, [field]) => {
            const value = stats[field as keyof Statistics];
            return sum + (typeof value === 'number' ? value : 0);
          }, 0);

        return sumRLD;
      }
    },
    'block_audit': {
      caption: 'Block Audit',
      feature: 'block_audit',
      unit: '',
      legend: '',
      enableZone: false,
      label: 'Block Audit (admin)',
      description: "Interface for modifying blocks.",
      adminOnly: true,
      fetchTargFromStats: (stats: Statistics, entity?: EntityType, stageType?: StageType, selectedRLDOptions?: SelectedRLDOptions) => {
        return 0;
      }
    },
    'row_audit': {
      caption: 'Row Audit',
      feature: 'row_audit',
      unit: '',
      legend: '',
      enableZone: false,
      label: 'Row Audit (admin)',
      description: "Interface for setting rows.",
      adminOnly: true,
      fetchTargFromStats: (stats: Statistics, entity?: EntityType, stageType?: StageType, selectedRLDOptions?: SelectedRLDOptions) => {
        return 0; 
      }
    },
    'trunk_audit': {
      caption: 'Trunk Audit',
      feature: 'trunk_audit',
      unit: '',
      legend: '',
      enableZone: false,
      label: 'Trunk Audit (admin)',
      description: "Interface for modifying trunks.",
      // entityType: EntityType.Trees,
      adminOnly: true,
      fetchTargFromStats: (stats: Statistics, entity?: EntityType, stageType?: StageType, selectedRLDOptions?: SelectedRLDOptions) => {
        return 0; 
      }
    },
    'master_trunk_audit': {
      caption: 'Master Trunk Audit',
      feature: 'master_trunk_audit',
      unit: '',
      legend: '',
      enableZone: false,
      label: 'Master Trunk Audit (admin)',
      description: "Interface for modifying trunks.",
      adminOnly: true,
      fetchTargFromStats: (stats: Statistics, entity?: EntityType, stageType?: StageType, selectedRLDOptions?: SelectedRLDOptions) => {
        return 0; 
      }
    }
  };

  // TODO this is a short term hack, instead move stageType from app.tsx into this context
  useEffect(() => {
    if (initialStageType !== null) {
      setStageType(initialStageType);
    }
  }, [initialStageType]);

  return (
    <MapStateContext.Provider value={{
      lowerBound,
      setLowerBound,
      upperBound,
      setUpperBound,
      viewZoneMap,
      setViewZoneMap,
      map,
      plotConfigs,
      isImperial,
      setIsImperial,
      selectedRLDOptions,
      setSelectedRLDOptions,
      rowStats,
      setRowStats,
      numTargetRows,
      setNumTargetRows,
      customTarg,
      setCustomTarg,
      stageType,
      setStageType,
      isTaskMapDialogOpen, 
      setIsTaskMapDialogOpen
    }}>
      {children}
    </MapStateContext.Provider>
  );
};

export const useMapState = () => {
  const context = useContext(MapStateContext);
  if (context === undefined) {
    throw new Error('useMapState must be used within a MapStateProvider');
  }
  return context;
};
