export type Statistics = {
  avg_canopy_area_m2: number;
  avg_fruit_diam: number | null;
  avg_fruitlet_diam: number | null;
  avg_fruit_vol: number | null;
  avg_fruit_weight_kg: number | null;
  avg_hue: number;
  p1_hue: number;
  p99_hue: number;
  avg_trunk_xsarea: number;
  median_trunk_xsarea: number;
  block_id: number;
  fruit_per_tree_calibrated: number;
  fruit_per_tree_detected: number;
  scan_id: string;
  total_fruit_calibrated: number | null;
  total_fruit_detected: number;
  calibration_ratio: number | null;
  total_trees: number;
  percent_trees: number;
  block_acreage: number | null;
  avg_tree_spacing_ft: number | null;
  avg_tree_diam_in: number | null;
  median_tree_diam_in: number | null;
  avg_tree_area_in2: number | null;
  avg_coverage: number;
  avg_canopy_height_m: number | null;
  block_name: string;
  timestamp: string;
  ccsa_red_m2: number | null;
  ccsa_yellowred_m2: number | null;
  ccsa_yellow_m2: number | null;
  ccsa_wilted_m2: number | null;
  avg_canopy_hue: number | null;
  avg_trunk_height_m: number | null;
  median_trunk_height_m: number | null;
  std_trunk_height_m: number | null;
  p25_trunk_height_m: number | null;
  p75_trunk_height_m: number | null;
}

export type CustomTarget = {
  targetValue: number | null;
  targetFeature: string | null;
  targetUnit: string | null;
  normalizationValue: number | null;
  normalizationFeature: 'xs_area_in2' | 'canopy_area_m2' | null;
  normalizationUnit: 'per in² TCSA' | 'per m² CCSA' | null;
};

export const DEFAULT_CUSTOM_TARGET: CustomTarget = {
  targetValue: null,
  targetFeature: null,
  targetUnit: null,
  normalizationValue: null,
  normalizationFeature: null,
  normalizationUnit: null
};

export type Block = {
  block_name: string;
  block_id: number;
  location: Coordinate;
  acreage: number;
}

export type Coordinate = {
  lat: number
  long: number
}

export type Orchard = {
  name: string;
  address: string;
  initialLocation: Coordinate;
  code: string;
  total_acres: number;
  section_code_prefix: number;
  seasons: string[]; 
}

export type BlockScanMap = {
  [key: string]: {
    center_lat: number; // TODO: refactor into coordinate type
    center_lon: number;
    scans: number[];
    last_scan_timestamp: string;
    last_scan_id: number;
    last_scan_raw_name: string;
    block_unique_id: string;
  };
};

export enum EntityType {
  Trees = 'trees',
  Fruits = 'fruits'
}

export enum FruitType {
  Apple = 'Apples',
  Grape = 'Grapes',
  Cherry = 'Cherries',
  Other = 'Other'
}

export enum StageType {
  Bud = 'Dormant Buds',
  SilverTip = 'Silver Tip',
  GreenTip = 'Green Tip',
  TightCluster = 'Tight Cluster',
  Pink = 'Pink',
  KingBloom = 'King Bloom',
  FullBloom = 'Full Bloom',
  PetalFall = 'Petal Fall',
  EarlyFruitSet = 'Early Fruit Set',
  Fruit = 'Fruit',
}

// This should look exactly like the ScanLabel enum in sql_types.py
export enum ScanLabel {
  PrePrune = 'pre_prune',
  PostPrune = 'post_prune',
  PreHandThin = 'pre_hand_thin',
  PostHandThin = 'post_hand_thin',
  FruitletCluster = 'fruit_set_cluster',
  FruitSizing = 'fruit_sizing',
}

export enum ViewMode {
  Block = 'Block',
  Orchard = 'Orchard'
}

export type ScanInfo = {
  avg_tree_spacing: number;
  block_id: number;
  block_name: string;
  buds_counted: number;
  entity_type: EntityType;
  fruit_type: FruitType;
  has_ground_truth: boolean;
  location: Coordinate;
  notes: string;
  rows_in_block: number;
  rows_scanned: number;
  run_master: boolean;
  scan_id: number;
  raw_scan_name: string;
  stage_type: StageType;
  technician_name: string;
  time: string;
  tree_structure: string; // Could be made into an enum if there are fixed options
  truthful: boolean;
  variety_type: string; // Could be made into an enum if there are fixed options
  scan_name: string;
  label: ScanLabel;
}

export type SelectedRLDOptions = {
  ccsa_red_m2: boolean;
  ccsa_yellowred_m2: boolean;
  ccsa_yellow_m2: boolean;
  ccsa_wilted_m2: boolean;
}