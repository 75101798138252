import { Box, Checkbox, FormControlLabel, FormGroup, Tab, Tabs, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useMapState } from '../../context/MapStateContext';

const RLDLegend = ({ 
    stats, 
    config, 
    upperBound, 
    setUpperBound, 
    applyPercentageToTarget, 
    convertToBoundOfTarget, 
    selectAll, 
    StyledTextField,
    setActiveRLDTab,
    activeRLDTab,
    rldPercent,
    setRldPercent,
    treeData
}) => {

    const { selectedRLDOptions, setSelectedRLDOptions, isImperial } = useMapState();

    const options = [
        { value: 'ccsa_red_m2', label: 'Red Leaf' },
        { value: 'ccsa_yellowred_m2', label: 'Red & Yellow Leaf' },
        { value: 'ccsa_yellow_m2', label: 'Yellow Leaf' },
        { value: 'ccsa_wilted_m2', label: 'Wilted Leaf' }
    ];

    const [isFirstFieldEditable, setIsFirstFieldEditable] = useState(true);

    const handleChange = (event) => {
        setSelectedRLDOptions({
            ...selectedRLDOptions,
            [event.target.name]: event.target.checked
        });
    };

    const handleUpperBoundChange = (event) => {
        convertToBoundOfTarget(event, setUpperBound);
    };

    const handleRldPercentChange = (event) => {
        const value = event.target.value;
        if (value === '') {
            setRldPercent(0);
        } else {
            let numericValue = parseFloat(value);
            if (!isNaN(numericValue)) {
                if (numericValue < 0) {
                    setRldPercent(0);
                } else if (numericValue > 100) {
                    setRldPercent(100);
                }
                else{
                    setRldPercent(numericValue);
                }
            }
        }
    };

    const handlePercentChange = (event) => {
        if (event.target.value === '') {
            setUpperBound('');
        } else {
            const newPercent = parseFloat(event.target.value);
            if (!isNaN(newPercent)) {
                setUpperBound(newPercent);
            }
        }
    };

    const handleTabChange = (event, newValue) => {
        setActiveRLDTab(newValue);
    };

    const handleSwapFields = () => {
        setIsFirstFieldEditable(!isFirstFieldEditable);
    };

    const [rldStats, setRldStats] = useState({ count: 0, percentage: 0 });

    useEffect(() => {
        const calculateRldStats = () => {
            if (!treeData || !treeData.features) return { count: 0, percentage: 0 };

            let numTrees = 0;
            const totalTrees = treeData.features.length;
            const threshold = activeRLDTab === 'RLD' ? applyPercentageToTarget(upperBound) : rldPercent;

            treeData.features.forEach(feature => {
                const properties = feature.properties;
                
                const totalRLD = Object.entries(selectedRLDOptions)
                    .filter(([key, isSelected]) => isSelected)
                    .reduce((sum, [key]) => sum + (properties[key] || 0), 0);

                // Convert m² to ft² if isImperial is true
                const convertedTotalRLD = config.convert(totalRLD);

                let rldValue;
                if (activeRLDTab === 'RLD') {
                    rldValue = convertedTotalRLD;
                } else { // 'RLD/CCSA'
                    rldValue = properties.canopy_area_m2 ? (totalRLD / properties.canopy_area_m2) * 100 : 0;
                }

                if (rldValue > threshold) {
                    numTrees++;
                }
            });

            return {
                count: numTrees,
                percentage: totalTrees > 0 ? (numTrees / totalTrees) * 100 : 0
            };
        };

        setRldStats(calculateRldStats());
    }, [treeData, activeRLDTab, upperBound, rldPercent, isImperial, selectedRLDOptions]);

    return (
        <div className='flex items-center w-full'>
            <div className='flex flex-col w-full'>
                <div className='w-full'>
                    <Tabs 
                    value={activeRLDTab} 
                    onChange={handleTabChange} 
                    aria-label="RLD tabs"
                    className="border-b border-black-300"
                    >
                        <Tab 
                            label={`RLD as % of Canopy Area`} 
                            value="RLD/CCSA" 
                            sx={{ 
                                '&.Mui-selected': { color: 'black', fontWeight: 'bold' },
                                '&:not(.Mui-selected)': { color: 'grey', fontWeight: 'bold' }
                            }}
                        />
                        <Tab 
                            label={`RLD Area per Vine (${config.unit})`} 
                            value="RLD" 
                            sx={{ 
                                '&.Mui-selected': { color: 'black', fontWeight: 'bold' },
                                '&:not(.Mui-selected)': { color: 'grey', fontWeight: 'bold' }
                            }}
                        />
        
                    </Tabs>
                </div>
                <div className='flex'>
                <div className='w-3/5'>
                    <Box className="pt-4 pl-4 min-h-[130px]">
                        {activeRLDTab === 'RLD' && (
                            <div className="flex flex-col space-y-2 relative">
                                <div 
                                    className={`transition-all duration-300 ease-in-out ${isFirstFieldEditable ? 'scale-100 opacity-100' : 'scale-90 opacity-70'}`}
                                    onClick={!isFirstFieldEditable ? handleSwapFields : undefined}
                                >
                                    <StyledTextField
                                        type="number"
                                        value={applyPercentageToTarget(upperBound)}
                                        onChange={isFirstFieldEditable ? handleUpperBoundChange : undefined}
                                        onFocus={isFirstFieldEditable ? selectAll : undefined}
                                        InputProps={{
                                            endAdornment: <span>{config.unit}</span>,
                                            readOnly: !isFirstFieldEditable,
                                        }}
                                        size="small"
                                        className={`w-[180px] ${!isFirstFieldEditable ? 'cursor-pointer' : ''}`}
                                        variant="standard"
                                        label="Red dot when RLD more than:"
                                    />
                                </div>
                          
                                <div 
                                    className={`transition-all duration-300 ease-in-out ${!isFirstFieldEditable ? 'scale-100 opacity-100' : 'scale-90 opacity-70'}`}
                                    onClick={isFirstFieldEditable ? handleSwapFields : undefined}
                                >
                                    <StyledTextField
                                        type="number"
                                        value={Math.round(upperBound)}
                                        onChange={!isFirstFieldEditable ? handlePercentChange : undefined}
                                        onFocus={!isFirstFieldEditable ? selectAll : undefined}
                                        InputProps={{
                                            endAdornment: <span>%</span>,
                                            readOnly: isFirstFieldEditable,
                                        }}
                                        size="small"
                                        className={`w-[180px] ${isFirstFieldEditable ? 'cursor-pointer' : ''}`}
                                        variant="standard"
                                        label="% of Avg RLD, across all vines"
                                    />
                                </div>
                            </div>
                        )}
                        {activeRLDTab === 'RLD/CCSA' && (
                            <div className="flex flex-col space-y-2">
                                <StyledTextField
                                    type="number"
                                    value={rldPercent === 0 ? '' : rldPercent}
                                    onChange={handleRldPercentChange}
                                    onFocus={selectAll}
                                    InputProps={{
                                        endAdornment: <span style={{ whiteSpace: 'nowrap' }}>% of canopy</span>,
                                    }}
                                    size="small"
                                    style={{ width: '180px' }}
                                    variant="standard"
                                    label="Red dot when RLD more than:"
                                    inputProps={{
                                        min: 0,
                                        max: 100,
                                    }}
                                />
                            </div>
                        )}
                    </Box>

                    <div className='flex justify-between'>
                        <Typography variant="body2">
                            Vines above threshold: {rldStats.count} ({rldStats.percentage.toFixed(2)}%)
                        </Typography>
    
                    </div>
                </div>

                <div className='width-1/3 ml-auto whitespace-nowrap'>
                    <Box className="h-full">
                        <Typography variant="body2" className="block">
                            What counts as RLD?:
                        </Typography>
                        <FormGroup className="space-y-[-8px]">
                            {Object.entries(selectedRLDOptions).map(([key, value]) => (
                                <FormControlLabel
                                    key={key}
                                    control={
                                        <Checkbox
                                            checked={value}
                                            onChange={handleChange}
                                            name={key}
                                            size="small"
                                        />
                                    }
                                    label={
                                        <Typography variant="body2">
                                            {options.find(opt => opt.value === key)?.label || key}
                                        </Typography>
                                    }
                                />
                            ))}
                        </FormGroup>
                    </Box>
                </div>
                </div>
            </div>

        </div>
    );
};

export default RLDLegend;
